import React,{useState,useEffect,useRef} from 'react';
import moment from 'moment'
import { CURRENCY } from 'variables/app';
import { useReactToPrint } from 'react-to-print';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Table,
    CardTitle
  } from "reactstrap";
  import Header from 'components/Headers/Header';

  import { useApi, useToast } from "../../../hooks";

  
const General = () => {

    var date = moment().format('YYYY-MM-DD'); 
    const [startDate, setStartDate] = useState(date)
    const [endDate, setEndDate] = useState(date)
    const [isGeneral, setIsGeneral] = useState(true)

    const [reportData, setReportData] = useState({
        balances:{
            totalCash:0,
            totalBalance:0,
            data:[]
        },
        quotes:{
            data:[],
            total:0,
            totalExpenses:0,
            totalLoans:0,
            totalPays:0,
        }
    })

    const [users, setUsers] = useState([])
    const toast = useToast();
    const { getUsers, getReportGeneral } = useApi();

    useEffect(()=>{
        getUserList();
    }, [])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getReportGeneralData = async () =>{
        
        const { response, error } = await getReportGeneral(startDate, endDate);
        if (response) {
            const { content, message } = response.data;
            setReportData(content)

        } else if (error) {
            error.message && toast.error(error.message);
        }
    
    }

    const getUserList = async () =>{
    
        const { response, error } = await getUsers();
        if (response) {
        const { content, message } = response.data;
        setUsers(content);
        } else if (error) {
            error.message && toast.error(error.message);
        }

    }

    const renderOptions = (list) => {
        return list.map((prop,key) => {
            return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
        });
    }

    
    const renderTableExpenses = (expenses) => {
        let i = 0;
        let data = expenses.map((prop, key) => {
            i++;
            return  (
                 <tr key={prop.id}>
                    <td>{i}</td>
                    <td>{prop.detail}</td>
                    <td>{prop.expense_type.name}</td>
                    <td>{prop.date}</td>
                    <td>{prop.value}</td>
                </tr>
                
            )
        })
        return data;
    }

    const renderReportQuotes = (quotes) => {
        let i = 0;
        let data = quotes.map((prop, key) => {
            i++;
            return  (
                 <tr key={`q${prop.id}`}>
                    <td>{i}</td>
                    <td>{prop.name}</td>
                    <td>{CURRENCY.format(prop.pays)}</td>
                    <td>{CURRENCY.format(prop.loans)}</td>
                    <td>{CURRENCY.format(prop.expenses)}</td>
                    <td>{CURRENCY.format(prop.pays-prop.loans-prop.expenses)}</td>
                </tr>
                
            )
        })
        return data;
    }

    const renderReportBalance = (balances) => {
        let i = 0;
        let data = balances.map((prop, key) => {
            i++;
            return  (
                <tr key={`b${prop.id}`}>
                    <td>{i}</td>
                    <td>{prop.name}</td>
                    <td>{CURRENCY.format(prop.balance)}</td>
                    <td>{CURRENCY.format(prop.cash)}</td>
                    <td>{prop.cash_date}</td>
                </tr>
                
            )
        })
        return data;
    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                                            Reporte General
                                        </h6>
                                        <Row>
                                        <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="startDate">
                                                        Fecha Inicio
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="startDate"
                                                    name="startDate"
                                                    value={startDate}
                                                    onChange={e=>{setStartDate(e.target.value)}}
                                                    type="date" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="endDate">
                                                        Fecha Fin
                                                    </label>
                                                    <Input 
                                                        name = "endDate"
                                                        className="form-control-alternative"
                                                        id="endDate"
                                                        value={endDate}
                                                        onChange={e=>{setEndDate(e.target.value)}}
                                                        type="date" />
                                                </FormGroup>
                                            </Col> 
                                            <Col lg="2">
                                                <Button className="my-4" color="primary" type="submit" onClick={()=>{getReportGeneralData()}}>
                                                    Consultar
                                                </Button>
                                            </Col>  
                                            <Col lg="1">
                                                <Button className="my-4" color="secundary" type="info" onClick={handlePrint}>Imprimir</Button>
                                            </Col>                                 
                                        </Row>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                 <Row>
                                    <Col xl="6">
                                        <Card className="bg-gradient-default">
                                            <CardBody>
                                                <CardTitle className="text-white">Reporte General</CardTitle>
                                                <span className="h4 font-weight-bold mb-0 text-white">Pagos {CURRENCY.format(reportData.quotes.totalPays)}</span><br/>
                                                <span className="h4 font-weight-bold mb-0 text-white">Ventas {CURRENCY.format(reportData.quotes.totalLoans)}</span><br/>
                                                <span className="h4 font-weight-bold mb-0 text-white">Gastos {CURRENCY.format(reportData.quotes.totalExpenses)}</span><br/>
                                                <span className="h2 font-weight-bold mb-0 text-white">Total {CURRENCY.format(reportData.quotes.total)}</span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl="6">
                                        <Card className="bg-gradient-primary">
                                            <CardBody>
                                                <CardTitle>Saldos</CardTitle>
                                                <span className="h3 font-weight-bold mb-0 text-white">Total Saldo {CURRENCY.format(reportData.balances.totalBalance)}</span><br/>
                                                <span className="h3  font-weight-bold mb-0 text-white">Total Efectivo {CURRENCY.format(reportData.balances.totalCash)}</span><br/><br/><br/>
                                                
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <br/>
                                <PrintComponent startDate={startDate} endDate={endDate} reportData={reportData}  ref={componentRef} />
                                {/*
                                {
                                    isGeneral?
                                    <>
                                        <h6 className="heading-small text-muted mb-4">
                                            Reporte General
                                        </h6>
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Usuario</th>
                                                    <th scope="col">Pagos</th>
                                                    <th scope="col">Ventas</th>
                                                    <th scope="col">Gastos</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportData.quotes.data.length > 0 && renderReportQuotes(reportData.quotes.data)}
                                            </tbody>
                                        </Table>
                                    </>:
                                    <>
                                        <h6 className="heading-small text-muted mb-4">
                                            Saldos
                                        </h6>
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Usuario</th>
                                                    <th scope="col">Saldo</th>
                                                    <th scope="col">Efectivo</th>
                                                    <th scope="col">Fecha</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportData.balances.data.length > 0 && renderReportBalance(reportData.balances.data)}
                                            </tbody>
                                        </Table>
                                    </>
                                } */}
                                
                            </CardBody>
                        </Card>
                    </Col>
            
                </Row>
            </Container>
            
        </>
    );
};

class PrintComponent extends React.Component  {

    renderReportQuotes = (quotes) => {
        let i = 0;
        let data = quotes.map((prop, key) => {
            i++;
            return  (
                 <tr key={`q${prop.id}`}>
                    <td>{i}</td>
                    <td>{prop.name}</td>
                    <td>{CURRENCY.format(prop.pays)}</td>
                    <td>{CURRENCY.format(prop.loans)}</td>
                    <td>{CURRENCY.format(prop.expenses)}</td>
                    <td>{CURRENCY.format(prop.pays-prop.loans-prop.expenses)}</td>
                </tr>
                
            )
        })
        data.push(
            <tr className="thead-light rowReport">
                <th colSpan="2" scope="col">TOTALES</th>
                <th scope="col">{CURRENCY.format(this.props.reportData.quotes.totalPays)}</th>
                <th scope="col">{CURRENCY.format(this.props.reportData.quotes.totalLoans)}</th>
                <th scope="col">{CURRENCY.format(this.props.reportData.quotes.totalExpenses)}</th>
                <th scope="col">{CURRENCY.format(this.props.reportData.quotes.total)}</th>
            </tr>
        )
        return data;
    }

    renderReportBalance = (balances) => {
        let i = 0;
        let data = balances.map((prop, key) => {
            i++;
            return  (
                <tr key={`b${prop.id}`}>
                    <td>{i}</td>
                    <td>{prop.name}</td>
                    <td>{CURRENCY.format(prop.balance)}</td>
                    <td>{CURRENCY.format(prop.cash)}</td>
                    <td>{prop.cash_date}</td>
                </tr>
                
            )
        })
        data.push(
            <tr className="thead-light rowReport">
                <th colSpan="2" scope="col">TOTALES</th>
                <th scope="col">{CURRENCY.format(this.props.reportData.balances.totalBalance)}</th>
                <th scope="col">{CURRENCY.format(this.props.reportData.balances.totalCash)}</th>
                <th></th>
            </tr>
        )
        return data;
    }

    render() {
        return (
            <>
                <div>
                    <Row>
                        <h6 className="heading-small text-muted mb-4">
                            Reporte General  -  Fecha: {this.props.startDate} a {this.props.endDate}
                        </h6>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Usuario</th>
                                    <th scope="col">Pagos</th>
                                    <th scope="col">Ventas</th>
                                    <th scope="col">Gastos</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.reportData.quotes.data.length > 0 && this.renderReportQuotes(this.props.reportData.quotes.data)}
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <h6 className="heading-small text-muted mb-4">
                            Saldos
                        </h6>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Usuario</th>
                                    <th scope="col">Saldo</th>
                                    <th scope="col">Efectivo</th>
                                    <th scope="col">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.reportData.balances.data.length > 0 && this.renderReportBalance(this.props.reportData.balances.data)}
                            </tbody>
                        </Table>
                    </Row>
                </div>
            </>
        );
    }
}


export default General;