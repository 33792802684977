import React from 'react';
import {
    Pagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap';

const renderPages = (pagination, handlePage) =>{
    let components =[];
    for (let i = 0; i < pagination.pages; i++) {
        let page = i+1;
        components.push(
            <PaginationItem key={`page_${page}`}
                className={page == pagination.page ? 'active': ''} >
                <PaginationLink
                    href="#"
                    onClick={() => {
                        page != pagination.page && handlePage({
                            page,
                            pages:pagination.pages,
                            perPage: pagination.perPage 
                        })
                    }
                    }
                >
                {page}
                </PaginationLink>
            </PaginationItem>
        )
        
    }
    return components;
}

const PaginationComponent = ({pagination, handlePage}) => {
    return (
        <>
            <nav aria-label="...">
                <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                    >
                    {pagination.page == 1 || pagination.pages == 1 ? <></> : 
                    <PaginationItem className="disabled">
                        <PaginationLink
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tabIndex="-1"
                        >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                        </PaginationLink>
                    </PaginationItem>}
                    
                    {renderPages(pagination, handlePage)}
                    
                    {pagination.page == pagination.pages  ? <></> :
                    <PaginationItem>
                        <PaginationLink
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                        </PaginationLink>
                    </PaginationItem>}
                </Pagination>
            
            </nav>
        </>
    );
};

export default PaginationComponent;