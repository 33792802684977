/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState,useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormText,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Badge,
  Table
} from "reactstrap";
import { useForm } from "react-hook-form";
// core components
import Header from "components/Headers/Header.js";
import { useApi, useToast, useUser } from "../../hooks";
import PaginationComponent from "components/Pagination";
import Loan from "./Loan";
import History from "./History";
import { useSelector } from 'react-redux'
import { getFilterUsers } from "../../selectors/jobs";
import { CURRENCY } from '../../variables/app';


const Customers = () => {

  const company = useSelector(state => state.company.company)
  const { register, handleSubmit, errors, setValue, reset  } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange"
  });

  const [customers, setCustomers] = useState([])
  const [users, setUsers] = useState([])
  const [user, setUser] = useState(null)
  const [customer, setCustomer] = useState(null)
  const [filter, setFilter] = useState(null)
  const [report, setReport] = useState("all")
  const [update, setUpdate] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalHistoryOpen, setHistoryModalOpen] = useState(false)
  const [pagination, setPagination] = useState({
    perPage: 200,
    page:1,
    pages:0
  });

  const toast = useToast()
  const userHook = useUser()
  const { storeCustomer, getCustomersByUserPagination, getUsers, changeCustomerStatus, deleteCustomer } = useApi();

  const getUserList = async () =>{
   
    const { response, error } = await getUsers();
    if (response) {
      const { content, message } = response.data;
      setUsers(getFilterUsers(content, company.id));
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }

  const getCustomerListByUserPage = async (paginationObject) =>{
   
    
    let name = filter;
    if(!name || name == ""){
      name = "null"
    }else{
      pagination.page =1
    }
    
    const userID = user? user: "null"
    const { response, error } = await getCustomersByUserPagination(paginationObject, userID, report, name );
    if (response) {
      const { content, message } = response.data;
      setCustomers(content.data);
      setPagination({
        perPage: content.per_page,
        page: content.current_page,
        pages: content.last_page
      })
    } else if (error) {
        error.message && toast.error(error.message);
    }
    
    
  }

  const openModal = (customer) =>{
      setCustomer(customer)
      setModalOpen(true)
  }

  const closeModal = ()=>{
    setCustomer(null)
    setModalOpen(false)
  }

  const openHistoryModal = (customer) =>{
    setCustomer(customer)
    setHistoryModalOpen(true)
  } 

  const closeHistoryModal = ()=>{
    setCustomer(null)
    setHistoryModalOpen(false)
  }

  const saveCustomer = async data => {
    if(!update){
      delete data.id;
    }
    const { response, error } = await storeCustomer(data);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      if(!update){
        //getCustomerListByUserPage(pagination)
        //setCustomers((lastCustomers)=> [...lastCustomers, content])
      }else{
        getCustomerListByUserPage(pagination)
      }
      reset();
    } else if (error) {
      if(error.response.status == 400){
        error.message && toast.error(error.response.data.message);
      }else{
        error.message && toast.error(error.message);
      } 
    }
  }

  const changeStatusCustomer = async data => {
    
    const object = {
      customerId: data.id,
      status: data.status
    }
    const { response, error } = await changeCustomerStatus(object);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      
      getCustomerListByUserPage(pagination)
      
    } else if (error) {
      if(error.response.status == 400){
        error.message && toast.error(error.response.data.message);
      }else{
        error.message && toast.error(error.message);
      } 
    }
  }

  const deleteCustomerRow = async data => {
    if (window.confirm("Está seguro que desea eliminar la el cliente?")) {
      const object = {
        customerId: data.id
      }
      const { response, error } = await deleteCustomer(object);
      if (response) {
        //console.log(response);
        const { content, message } = response.data;
        toast.success(message);
        getCustomerListByUserPage(pagination)
        
      } else if (error) {
        if(error.response.status == 400){
          error.message && toast.error(error.response.data.message);
        }else{
          error.message && toast.error(error.message);
        } 
      }
    }
  }

  const fillCustomer = (customer) =>{
   
    setValue([
      { id : customer.id },
      { identification : customer.identification },
      { name : customer.name },
      { phone : customer.phone },
      { address : customer.address },
      { userId : customer.user.id }
    ])

    setUpdate(true)

  }

  const renderOptions = (list) => {
    return list.map((prop,key) => {
      return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
    });
  }

  const renderTable = (customers) => {
    let i = 0;
    let result = [];
    let data = customers.map((prop, key) => {
      
      if((prop.quantity && prop.quantity == 0) || !prop.quantity){
        i++;
        result.push(
          <tr key={prop.id}>
            <td>{i}</td>
            <th scope="row">{prop.identification}</th>
            <td>{prop.name}</td>
            <td>{prop.phone}</td>
            <td>{prop.address}</td>
            <td>{CURRENCY.format(prop.balance)}</td>
            <td>{prop.user?.name}</td>
            <td>
              {
                prop.status == 1? 
                <Badge color="" className="badge-dot mr-4">
                  <i className="bg-success" />
                  Activo
                </Badge> : 
                <Badge color="" className="badge-dot mr-4">
                  <i className="bg-warning" />
                  Inactivo
                </Badge>
              }
              
            </td>
          <td className="text-left">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#"
                role="button"
                size="sm"
                color=""
                onClick={e => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  href="#"
                  onClick={() => fillCustomer(prop)}
                >
                  Actualizar
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() => changeStatusCustomer(prop)}
                >
                  Cambiar Estado
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={()=> {openModal(prop)}}
                >
                  Nuevo Préstamo
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={()=> {openHistoryModal(prop)}}
                >
                  Historial de pagos y prestamos
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={()=> deleteCustomerRow(prop)}
                >
                  Eliminar
                </DropdownItem>
                
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        
        </tr>
        
        )
      }
        
    })
    return result;
  }
  
  const onPagination = (pagination)=>{
    getCustomerListByUserPage(pagination);
  }
   
  useEffect(()=>{
    getUserList();
    

  }, [])

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Crear cliente</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(saveCustomer)}>
                  <h6 className="heading-small text-muted mb-4">
                    Información Personal
                  </h6>
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="identification"
                        >
                          Número de identificación
                        </label>
                        <Input 
                            name = "identification"
                            className="form-control-alternative"
                            id="identification"
                            placeholder="Número de identificación"
                            type="text"
                            innerRef={register({ required: true, pattern: /\d+/gi})} 
                        />
                        {!!errors?.identification?.types?.required && <FormText>Campo requerido.</FormText>}
                        {!!errors?.identification?.types?.pattern && <FormText>Escribe solo números.</FormText>}
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="name"
                        >
                          Nombre
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="name"
                          name="name"
                          placeholder="Nombre"
                          type="text"
                          innerRef={register({ required: true })} 
                        />
                        {!!errors?.name?.types?.required && <FormText>Campo requerido.</FormText>}
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="phone"
                        >
                          Teléfono
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="phone"
                          name="phone"
                          placeholder="Teléfono"
                          type="text"
                          innerRef={register({ required: true, pattern: /\d+/gi})} 
                        />
                        {!!errors?.phone?.types?.required && <FormText>Campo requerido.</FormText>}
                        {!!errors?.phone?.types?.pattern && <FormText>Escribe solo números.</FormText>}
                      </FormGroup>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="address"
                        >
                          Dirección
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="address"
                          name="address"
                          placeholder="Dirección"
                          type="text"
                          disabled={userHook.user.rol_id == 3?true:false}
                          innerRef={register({ required: true})} 
                        />
                        {!!errors?.address?.types?.required && <FormText>Campo requerido.</FormText>}
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="userId"
                        >
                          Cobrador
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="userId"
                          name="userId"
                          placeholder="Seleccionar una opción"
                          type="select"
                          disabled={userHook.user.rol_id == 3?true:false}
                          innerRef={register({ required: true })} >
                            <option></option>
                            {renderOptions(users)}
                        </Input>
                        {!!errors?.userId?.types?.required && <FormText>Campo requerido.</FormText>}
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <div className="text-center">
                        {
                          !update ? 
                          <Button className="my-4" color="primary" type="submit" >
                              Guardar
                          </Button>
                          :
                          <>
                            <Button className="my-4" color="danger" type="button" onClick={()=>{reset(); setUpdate(false)}} >
                                Cancelar
                            </Button>
                            <Button className="my-4" color="info" type="submit" >
                                Actualizar datos
                            </Button>
                          </>
                        }
                      </div>
                    </Col>
                    <Input
                        className="form-control-alternative"
                        hidden
                        id="id"
                        name="id"
                        type="number"
                        innerRef={register} 
                      />
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br/>
        <Row>
        <Col className="mb-5 mb-xl-0" xl="12">
          <Card className="shadow">
              <CardHeader className="border-1">
                <h3 className="mb-0">Lista Clientes</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="user"
                      >
                        Usuario
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="user"
                        name="user"
                        placeholder="Seleccionar una opción"
                        type="select"
                        onChange={e=>setUser(e.target.value)} >
                          <option></option>
                          {renderOptions(users)}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="report"
                      >
                        Todos
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="report"
                        name="report"
                        placeholder="Seleccionar una opción"
                        type="select"
                        onChange={e=>setReport(e.target.value)} >
                          <option value="all">Todos</option>
                          <option value="loans">Todos con prestamos</option>
                          <option value="no-loans">Todos Sin prestamos activos</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="filter"
                      >
                        Filtro
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="filter"
                        name="filter"
                        placeholder="Seleccionar una opción"
                        type="text"
                        onChange={e=>setFilter(e.target.value)} >
                      </Input>
                    </FormGroup>
                  </Col>
               
                  <Col lg="2">
                    <Button style={
                      {top:"10px"}
                    } className="my-4" color="primary" type="submit" onClick={()=>{getCustomerListByUserPage(pagination)}}>
                      Buscar
                    </Button>
                  </Col>     
                  
                </Row>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Identificación</th>
                      <th scope="col">Nombres</th>
                      <th scope="col">Teléfono</th>
                      <th scope="col">Dirección</th>
                      <th scope="col">Saldo</th>
                      <th scope="col">Usuario</th>
                      <th scope="col">Estado</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.length > 0 && renderTable(customers)}
                  </tbody>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </Table>
              </CardBody>
              <CardFooter className="py-4">
                <PaginationComponent pagination={pagination} handlePage={onPagination}/>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal
          className="modal-dialog-centered"
          isOpen={modalOpen}
          toggle={() => closeModal()}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Nuevo préstamo
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>closeModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Loan customer={customer} />
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered "
          size="lg" style={{maxWidth: '1600px', width: '85%'}}
          isOpen={modalHistoryOpen}
          toggle={() => closeHistoryModal()}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Historial de préstamos y pagos
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>closeHistoryModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <History customer={customer} />
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default Customers;


