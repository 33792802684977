import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import useWindowSize from "../hooks/useWindowsSize";
import NewSidebar from '../components/Sidebar/NewSidebar';

function Admin(props) {

  const [ collapsed, setCollapsed ] = useState(true);
  const ref = useRef(null);

  const { width } = useWindowSize();

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" || prop.layout === "/report") {
        return (
            <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
            />
        );
      } else {
        return null;
      }
    });
  };

  const toggler = () => setCollapsed(prev => !prev);

  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
          props.location.pathname.indexOf(
              routes[i].layout + routes[i].path
          ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    ref.current.scrollTop = 0;
  }, [])

  return(
      <>
        {width < 768
            ? (
                <Sidebar
                    {...props}
                    routes={routes}
                    logo={{
                      innerLink: "/admin/index",
                      imgSrc: require("assets/img/brand/logo2.png"),
                      imgAlt: "..."
                    }}
                />
            ) : (
                <NewSidebar {...props} collapsed={collapsed} handleCollapse={toggler} routes={routes} />
            )}
        <div className="main-content main-content-transition" data-collapsed={`${width < 768 ? 'none' : collapsed ? 'on' : 'off' }`} ref={ref}>
          <AdminNavbar
              {...props}
              brandText={getBrandText(props.location.pathname)}
          />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/index" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
  );
}

export default Admin;
