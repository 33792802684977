import React,{useState,useEffect} from 'react';
import {
    Button,
    FormGroup,
    FormText,
    Form,
    Input,
    Row,
    Col,
    Label,
    Table
  } from "reactstrap";
import { useToast,useApi } from '../../../hooks';
import { CURRENCY } from 'variables/app';
import { useForm } from "react-hook-form";

const BadQuotes = ({saleLoans, startDate, userReport}) => {

    const [badQuotesReport, setBadQuotesReport] = useState([])
    const toast = useToast();
    const { getBadQuotes } = useApi();

    const getBadQuoteList = async () =>{
   
        const { response, error } = await getBadQuotes(userReport, startDate);
        if (response) {
          const { content, message } = response.data;
          console.log(saleLoans, content)
          setBadQuotesReport(content);

        } else if (error) {
            error.message && toast.error(error.message);
        }
    
    }

    const validateList =(loan_id) => {
        let isValid = false
        saleLoans.forEach((prop)=>{

            if(prop.id == loan_id ){
                isValid = true
            }
        })
        return isValid
    }

    const renderReport =() =>{

        let id = 0
        let data = []
        badQuotesReport.map((prop, key) => {
            if(!validateList(prop.loan_id)){
                id++;
                data.push(
                    <tr key={`bq${prop.id}`}>
                        <td>{id}</td>
                        <td>{prop.customer.name}</td>
                        <td>{CURRENCY.format(prop.quota)}</td>
                        <td>{CURRENCY.format(prop.value)}</td>
                    </tr>
                )
            }
            
            
        })
        console.log(data);
        return data

    }

    useEffect(()=>{
        getBadQuoteList();
      }, [])
    
    return (
        <>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Cuota</th>
                        <th scope="col">Abono</th>
                    </tr>
                </thead>
                <tbody>
                    {badQuotesReport.length > 0 && renderReport()}
                </tbody>
            </Table> 
        </>
    );
};

export default BadQuotes;