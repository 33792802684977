/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,  { useState } from "react";
import { useHistory } from "react-router-dom";
import { useApi, useToast } from "../../hooks";
import { useDispatch } from "react-redux";
import authActions from '../../store/actions/auth';
import companyActions from '../../store/actions/company';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormText
} from "reactstrap";

import { useForm } from "react-hook-form";

const Login = () => {

  const { register, handleSubmit, errors } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange"
  });

  const [ logging, setLogging ] = useState(false);
  
  const toast = useToast();
  const { authUser } = useApi();
  const history = useHistory();

  const dispatch = useDispatch();

  const login = async data =>{
    
    const { response, error } = await authUser(data);
    if (response) {
      const { data } = response;
      //console.log(data.content)
      dispatch(authActions.loginSuccess(data.content));
      dispatch(companyActions.setCompany(data.content.companies[0]));

      toast.success(data.message);
      history.push('/admin');
    } else if (error) {
        error.response?.data?.message && toast.error(error.response.data.message);
        setLogging(false);
    }

  };

  //console.log(errors);

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-lighter shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
              <div className="btn-wrapper text-center">
                  
                  <img
                      width="180px"
                      alt="..."
                      src={require("assets/img/brand/logo.png")}
                  />
                  
              </div>
            <Form role="form" onSubmit={handleSubmit(login)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Usuario" name="username" type="text" autoComplete="user" innerRef={register({ required: true })} />  
                </InputGroup>
                {!!errors?.username?.types?.required && <FormText>Campo requerido.</FormText>}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Contraseña" name="password" type="password" autoComplete="password" innerRef={register({ required: true })} />
                </InputGroup>
                {!!errors?.password?.types?.required && <FormText>Campo requerido.</FormText>}
              </FormGroup>
              
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit" disabled={logging}>
                  Iniciar Sesión
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;

