import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
import storageLocal from 'redux-persist/lib/storage';
import createEncryptor from 'redux-persist-transform-encrypt';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import auth from './auth';
import company from './company';

const encryptor = createEncryptor({
    secretKey: 'josekrlos029.gestionapp',
    onError: function (error) {
        console.log(`Parece que hubo un error: ${error.messsage}`);
    }
});

const rootPersistConfig = {
    key: 'root',
    storage: storageLocal,
    whitelist: ['auth', 'company'],
    transforms: [encryptor],
    stateReconciler: autoMergeLevel2
};

const rootReducer = combineReducers({
    auth,
    company
});

export default persistReducer(rootPersistConfig, rootReducer);
