import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import {useApi} from "./index";

function useUser() {
    const dispatch = useDispatch();
    const user = useSelector(store => store.auth.user);
    const history = useHistory();
   
    return { user }
}

export default useUser;