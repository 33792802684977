/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';
import { NavLink as NavLinkRRD, Link, withRouter } from "react-router-dom";
import { getUser, getCompany } from "selectors/user";
import authActions from '../../store/actions/auth';
import companyActions from '../../store/actions/company';

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavItem,
  NavLink,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import routes from "routes";

class AdminNavbar extends React.Component {

  cerrarSesion = () => {
    this.props.logout()
    const { history } = this.props;
    return history.push("/auth/login")
  }
  setCompany = (company) => {
    this.props.changeCompany(company); 
    setTimeout(()=>{
      window.location.reload(false)
    }, 1500)
  }

  createLinkCompanies = () =>{
    const companies = this.props.usuario.companies
    if(companies){
      
      return companies.map((prop, key) => {
        
          return (
            <NavItem key={"lc"+key}>
              <NavLink
                to="#"
                tag={NavLinkRRD}
                onClick={()=>this.setCompany(prop)}
                activeClassName="active"
                style={{color:"black"}}
              > 
                {prop.name}
              </NavLink>
            </NavItem>
          );
        
      });
    }
    
  }

  createLinksReport = () => {
    const user = this.props.usuario
    return routes.map((prop, key) => {
      if(prop.layout === "/report" && prop.users.includes(user.rol_id)){
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              activeClassName="active"
              style={{color:"black"}}
            > 
            {prop.name}
            </NavLink>
          </NavItem>
        );
      }else{
        return null;
      }
      
    });
  };

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <i className="ni ni-building" /> 
                  <span> {this.props.company && this.props.company.name}</span>
                </DropdownToggle>
                <DropdownMenu tag="ul" right>
                  {this.createLinkCompanies()}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <i className="ni ni-chart-bar-32" /> 
                  <span> Reportes</span>
                </DropdownToggle>
                <DropdownMenu tag="ul" right>
                  {this.createLinksReport()}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/user.png")}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.props.usuario.name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem href="#" onClick={() => this.cerrarSesion()}>
                    <i className="ni ni-user-run" />
                    <span>Cerrar Sesión</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = state => ({
  usuario: getUser(state),
  company: getCompany(state)
});

export default withRouter(connect(mapStateToProps, {logout: authActions.logout, changeCompany: companyActions.setCompany})(AdminNavbar)) ;
