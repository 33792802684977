import { handleActions } from 'redux-actions';
import { companyKeys } from '../keys';

const { SET_COMPANY } = companyKeys;

const companyReducer = handleActions({
    [SET_COMPANY]: (state, action) => ({
        ...state,
        ...action.payload
    })
}, {
    selected: null,
    list: []
});

export default companyReducer;
