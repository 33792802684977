export const getFilterUsers = (users, company) => 
{
    //console.log(users, company)
    let realUsers = []
    for (let i = 0; i < users.length; i++) {
        
        if(users[i].company_id == company || users[i].is_master == 1){
            realUsers.push(users[i])
        }
    }

    return realUsers
};