import React,{useState,useEffect} from 'react';
import moment from 'moment'
import { CURRENCY } from 'variables/app';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Badge,
    Table,
    CardTitle
  } from "reactstrap";

  import Header from 'components/Headers/Header';
  import { useApi, useToast } from "../../../hooks";

  import { useSelector } from 'react-redux'
  import { getFilterUsers } from "../../../selectors/jobs";
  
const Sale = () => {

    const company = useSelector(state => state.company.company)
    var date = moment().format('YYYY-MM-DD'); 
    const [expenses, setExpenses] = useState([])
    const [users, setUsers] = useState([])
    const [startDate, setStartDate] = useState(date)
    const [endDate, setEndDate] = useState(date)
    const [userSale, setUserSale] = useState(null)
    const [isSale, setIsSale] = useState(true)
    const [saleData, setSaleData] = useState({
        expenses:{
            quantity:0,
            total:0,
            data:[]
        },
        quotes:{
            quantity:0,
            total:0,
        },
        bases:{
            quantity:0,
            total:0,
        },
        injections:{
            quantity:0,
            total:0,
        },
        loans:{
            quantity:0,
            total:0,
        },
        total:0,
        countCustomers:0
    })

    const toast = useToast();
    const { getUsers, getReportSale, storeSale } = useApi();

    useEffect(()=>{
        getUserList();
    }, [])
    

    const getReportSaleData = async () =>{
        if(userSale!=null){
            const { response, error } = await getReportSale(startDate, endDate, userSale);
            if (response) {
                const { content, message } = response.data;
                setSaleData(content)
                setIsSale(content.isSale)
                setExpenses(content.expenses.data)
            } else if (error) {
                error.message && toast.error(error.message);
            }
        }
    }

    const getUserList = async () =>{
    
        const { response, error } = await getUsers();
        if (response) {
            const { content, message } = response.data;
            setUsers(getFilterUsers(content, company.id));
        } else if (error) {
            error.message && toast.error(error.message);
        }

    }

    const saveSale = async () => {
        
        const object = {
            userId: userSale,
            date: startDate
        }

        const { response, error } = await storeSale(object);
        if (response) {
          //console.log(response);
          const { content, message } = response.data;
          toast.success(message);
          setIsSale(true)
        } else if (error) {
            if(error.response.status == 400){
                error.message && toast.error(error.response.data.message);
              }else{
                error.message && toast.error(error.message);
              } 
        }
      }

    const renderOptions = (list) => {
        return list.map((prop,key) => {
            return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
        });
    }

    
    const renderTableExpenses = (expenses) => {
        let i = 0;
        let data = expenses.map((prop, key) => {
            i++;
            return  (
                 <tr key={prop.id}>
                    <td>{i}</td>
                    <td>{prop.detail}</td>
                    <td>{prop.expense_type.name}</td>
                    <td>{prop.date}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                </tr>
                
            )
        })
        return data;
    }
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                                            Cuadre por día
                                        </h6>
                                        <Row>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="startDate">
                                                        Fecha Inicio
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="startDate"
                                                    name="startDate"
                                                    value={startDate}
                                                    onChange={e=>{setStartDate(e.target.value); setIsSale(false)}}
                                                    type="date" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="endDate">
                                                        Fecha Fin
                                                    </label>
                                                    <Input 
                                                        name = "endDate"
                                                        className="form-control-alternative"
                                                        id="endDate"
                                                        value={endDate}
                                                        onChange={e=>{setEndDate(e.target.value); setIsSale(false)}}
                                                        type="date" />
                                                </FormGroup>
                                            </Col> 
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="userId"
                                                    >
                                                    Usuario
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="userId"
                                                    name="userId"
                                                    placeholder="Seleccionar una opción"
                                                    type="select"
                                                    onChange={e=>setUserSale(e.target.value)}>
                                                        <option></option>
                                                        {renderOptions(users)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="2">
                                                <Button className="my-4" color="primary" type="submit" onClick={()=>getReportSaleData()}>
                                                    Consultar
                                                </Button>
                                            </Col>                                   
                                        </Row>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl="3">
                                        <Card className="bg-gradient-default">
                                            <CardBody>
                                                <CardTitle className="text-white">Cobrado <Badge className="badge-default">{saleData.countCustomers} </Badge></CardTitle>
                                                <span className="h4 font-weight-bold mb-0 text-white">{CURRENCY.format(saleData.quotes.total)}</span><br/>
                                                <span className="h4 font-weight-bold mb-0 text-white">#{saleData.countCustomers}</span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl="3">
                                        <Card className="bg-gradient-default">
                                            <CardBody>
                                                <CardTitle className="text-white">Base <Badge className="badge-default">{saleData.bases.quantity}</Badge></CardTitle>
                                                <span className="h4 font-weight-bold mb-0 text-white">{CURRENCY.format(saleData.bases.total)}</span><br/>
                                                <span className="h4 font-weight-bold mb-0 text-white">#{saleData.bases.quantity}</span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl="3">
                                        <Card className="bg-gradient-default">
                                            <CardBody>
                                                <CardTitle className="text-white">Inyección <Badge className="badge-default">{saleData.injections.quantity}</Badge></CardTitle>
                                                <span className="h4 font-weight-bold mb-0 text-white">{CURRENCY.format(saleData.injections.total)}</span><br/>
                                                <span className="h4 font-weight-bold mb-0 text-white">#{saleData.injections.quantity}</span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl="3">
                                        <Card className="bg-gradient-default">
                                            <CardBody>
                                                <CardTitle className="text-white">Prestado <Badge className="badge-default">{saleData.loans.quantity}</Badge></CardTitle>
                                                <span className="h4 font-weight-bold mb-0 text-white">{CURRENCY.format(saleData.loans.total)}</span><br/>
                                                <span className="h4 font-weight-bold mb-0 text-white">#{saleData.loans.quantity}</span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col xl="3">
                                        <Card className="bg-gradient-default">
                                            <CardBody>
                                                <CardTitle className="text-white">Gastos <Badge className="badge-default">{saleData.expenses.quantity}</Badge></CardTitle>
                                                <span className="h4 font-weight-bold mb-0 text-white">{CURRENCY.format(saleData.expenses.total)}</span><br/>
                                                <span className="h4 font-weight-bold mb-0 text-white">#{saleData.expenses.quantity}</span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl="9">
                                        <Card className="bg-gradient-primary">
                                            <CardBody>
                                                <Row>
                                                <Col xl="10">
                                                    <CardTitle>Total</CardTitle>
                                                    <span className="h2 font-weight-bold mb-0 text-white">{CURRENCY.format(saleData.total)}</span>
                                                </Col>
                                                <Col xl="2">
                                                    <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                                        <i className="ni ni-money-coins"></i>
                                                    </div>
                                                </Col>
                                                </Row>
                                                
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <br/>
                                {
                                    startDate == endDate && !isSale?
                                    <>
                                        <Row>
                                            <Col xl="10"></Col>
                                            <Col xl="2">
                                                <Button className="my-4" color="info" type="submit" onClick={()=>saveSale()}>
                                                    Liquidar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </> : 
                                    <>
                                    </>
                                }
                                
                                <h6 className="heading-small text-muted mb-4">
                                    Gastos
                                </h6>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Detalle</th>
                                            <th scope="col">Tipo de Gasto</th>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {expenses.length > 0 && renderTableExpenses(expenses)}
                                    </tbody>
                                </Table>

                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Sale;