import React,{useState,useEffect, useRef} from 'react';
import moment from 'moment'
import { CURRENCY } from 'variables/app';
import { useReactToPrint } from 'react-to-print';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Table,
    CardTitle
  } from "reactstrap";
  import Header from 'components/Headers/Header';

  import { useApi, useToast } from "../../../hooks";

  import { useSelector } from 'react-redux'
  import { getFilterUsers } from "../../../selectors/jobs";

  
const LoanDays = () => {

    const company = useSelector(state => state.company.company)
    var date = moment().format('YYYY-MM-DD'); 
    const [startDate, setStartDate] = useState(date)
    const [endDate, setEndDate] = useState(date)
    const [userReport, setUserReport] = useState(null)
    const [reportData, setReportData] = useState({
        loans:[],
        total:0
    })

    const [users, setUsers] = useState([])
    const toast = useToast();
    const { getUsers, getReportLoandate } = useApi();

    useEffect(()=>{
        getUserList();
    }, [])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getUserReport = userId =>{
        let user = null
        for (let index = 0; index < users.length; index++) {
            if(users[index].id==userId){
                user =users[index]
            }
            
        }
        return user;
    }
    
    const getReportLoanRange = async () =>{
        
        if(!userReport)
            return toast.error("Selecciona un usuario");
        const { response, error } = await getReportLoandate(startDate, endDate, userReport);
        if (response) {
            const { content, message } = response.data;
            setReportData(content)

        } else if (error) {
            error.message && toast.error(error.message);
        }
    
    }

    const getUserList = async () =>{
    
        const { response, error } = await getUsers();
        if (response) {
            const { content, message } = response.data;
            setUsers(getFilterUsers(content, company.id));
        } else if (error) {
            error.message && toast.error(error.message);
        }

    }

    const renderOptions = (list) => {
        return list.map((prop,key) => {
            return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
        });
    }

    const renderReportLoans = (quotes) => {
        let id = 0;
        let data = quotes.map((prop, key) => {
            id++;
            return  (
                 <tr key={`q${prop.id}`}>
                     <td>{id}</td>
                    <td>{prop.startDate}</td>
                    <td>{prop.customer.name}</td>
                    <td>{prop.customer.phone}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                    <td>{CURRENCY.format(prop.balance)}</td>
                    <td>{prop.payment_mode.name}</td>
                </tr>
                
            )
        })
        return data;
    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                                            Reporte De Préstamos del día
                                        </h6>
                                        <Row>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="startDate">
                                                        Fecha Inicio
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="startDate"
                                                    name="startDate"
                                                    value={startDate}
                                                    onChange={e=>{setStartDate(e.target.value)}}
                                                    type="date" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="endDate">
                                                        Fecha Fin
                                                    </label>
                                                    <Input 
                                                        name = "endDate"
                                                        className="form-control-alternative"
                                                        id="endDate"
                                                        value={endDate}
                                                        onChange={e=>{setEndDate(e.target.value)}}
                                                        type="date" />
                                                </FormGroup>
                                            </Col> 
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="userId"
                                                    >
                                                    Usuario
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="userId"
                                                    name="userId"
                                                    placeholder="Seleccionar una opción"
                                                    type="select"
                                                    onChange={e=>setUserReport(e.target.value)}>
                                                        <option></option>
                                                        {renderOptions(users)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="1">
                                                <Button className="my-4" color="primary" type="submit" onClick={()=>{getReportLoanRange()}}>
                                                    Consultar
                                                </Button>
                                            </Col>        
                                            <Col lg="1">
                                                <Button className="my-4" color="secundary" type="info" onClick={handlePrint}>Imprimir</Button>
                                            </Col>                               
                                        </Row>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl="12">
                                        <Card className="bg-gradient-default">
                                            <CardBody>
                                                <CardTitle className="text-white">Total</CardTitle>
                                                <span className="h3 font-weight-bold mb-0 text-white">{CURRENCY.format(reportData.total)}</span><br/>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <br/>
                                <PrintComponent startDate={startDate} endDate={endDate} reportData={reportData} username={userReport? getUserReport(userReport).name:""} ref={componentRef} />
                            </CardBody>
                        </Card>
                    </Col>
            
                </Row>
            </Container>
            
        </>
    );
};

class PrintComponent extends React.Component {

    renderReportLoans = (quotes) => {
        let id = 0;
        let data = quotes.map((prop, key) => {
            id++;
            return  (
                 <tr key={`q${prop.id}`}>
                     <td>{id}</td>
                    <td>{prop.startDate}</td>
                    <td>{prop.customer.name}</td>
                    <td>{prop.customer.phone}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                    <td>{CURRENCY.format(prop.balance)}</td>
                    <td>{prop.payment_mode.name}</td>
                </tr>
                
            )
        })
        data.push(
            <tr className="thead-light">
                <th colSpan="4" scope="col">TOTAL</th>
                <th scope="col">{CURRENCY.format(this.props.reportData.total)}</th>
                <th></th>
                <th></th>
            </tr>
        )
        return data;
    }

    render() {
        return (
            <>
                <div>
                    <Row>
                        <h6 className="heading-small text-muted mb-4">
                            Préstamos del día - {this.props.username} - Fecha: {this.props.startDate} a {this.props.endDate}
                        </h6>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col">Teléfono</th>
                                    <th scope="col">Valor</th>
                                    <th scope="col">Saldo</th>
                                    <th scope="col">Tipo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.reportData.loans.length > 0 && this.renderReportLoans(this.props.reportData.loans)}
                            </tbody>
                        </Table> 
                    </Row>
                </div>
            </>
            
        );
    }
}


export default LoanDays;