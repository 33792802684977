import React,{useState,useEffect} from 'react';
import {
    Button,
    FormGroup,
    FormText,
    Form,
    Input,
    Row,
    Col,
    Label,
    Table
  } from "reactstrap";
import { useToast,useApi } from '../../../hooks';
import { CURRENCY } from 'variables/app';

const Payments = ({startDate, userReport}) => {

    const [paymentsReport, setPaymentsReport] = useState([])
    const toast = useToast();
    const { getFinalSalePayment, deleteFinalSalePayment } = useApi();

    const getPaymentList = async () =>{
   
        const { response, error } = await getFinalSalePayment(userReport, startDate);
        if (response) {
          const { content, message } = response.data;
          setPaymentsReport(content);

        } else if (error) {
            error.message && toast.error(error.message);
        }
    
    }

    const deletePayment = async data => {
        if (window.confirm("Está seguro que desea eliminar pago?")) {
            const object = {
                paymentId: data.id
            }
            const { response, error } = await deleteFinalSalePayment(object);
            if (response) {
              //console.log(response);
              const { content, message } = response.data;
              toast.success(message);
              getPaymentList()
              
            } else if (error) {
              if(error.response.status == 400){
                error.message && toast.error(error.response.data.message);
              }else{
                error.message && toast.error(error.message);
              } 
            }
        }
        
      }

    const renderReport =() =>{

        let id = 0
        let data = []
        paymentsReport.map((prop, key) => {
            id++;
            data.push(
                <tr key={`bq${prop.id}`}>
                    <td>{id}</td>
                    <td>{prop.name}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                    <td>
                        <Button className="btn-2" color="danger" type="button" onClick={()=> deletePayment(prop)}>
                            <span className="btn-inner--text">X</span>
                        </Button>
                    </td>
                </tr>
            )
            
        })
        console.log(data);
        return data

    }

    useEffect(()=>{
        getPaymentList();
      }, [])
    
    return (
        <>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Pago</th>
                        <th scope="col">Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {paymentsReport.length > 0 && renderReport()}
                </tbody>
            </Table> 
        </>
    );
};

export default Payments;