/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState,useEffect} from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormText,
  Form,
  Input,
  Row,
  Col,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Badge,
  Table,
  Button
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useApi, useToast } from "../../../hooks";
import PaginationComponent from "../../../components/Pagination";

const ExpenseType = () => {

  const { register, handleSubmit, errors, setValue, reset  } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange"
  });

  const [expenseTypes, setExpenseTypes] = useState([])
  const [users, setUsers] = useState([])
  const [update, setUpdate] = useState(false)
  const [pagination, setPagination] = useState({
    perPage: 5,
    page:1,
    pages:0
  });

  const renderOptions = (list) => {
    return list.map((prop,key) => {
      return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
    });
  }

  const toast = useToast();
  const { storeExpenseType, getExpenseTypesPagination, changeExpenseTypeStatus, deleteExpenseType, getUsers } = useApi();

  const getUserList = async () =>{
   
    const { response, error } = await getUsers();
    if (response) {
      const { content, message } = response.data;
      setUsers(content);
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }

  const getExpenseTypeListPage = async (paginationObject) =>{
   
    const { response, error } = await getExpenseTypesPagination(paginationObject);
    if (response) {
      const { content, message } = response.data;
      setExpenseTypes(content.data);
      setPagination({
        perPage: content.per_page,
        page: content.current_page,
        pages: content.last_page
      })
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }
  
  const saveExpenseType = async data => {
    if(!update){
      delete data.id;
    }
    //console.log(data);
    const { response, error } = await storeExpenseType(data);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      if(!update){
        setExpenseTypes((lastExpenseTypes)=> [...lastExpenseTypes, content])
      }else{
        getExpenseTypeListPage(pagination)
        setUpdate(false)
      }
      reset();
    } else if (error) {
      if(error.response.status == 400){
        error.message && toast.error(error.response.data.message);
      }else{
        error.message && toast.error(error.message);
      } 
    }
  }

  const changeStatusExpenseType = async data => {
    
    const object = {
      id: data.id,
      status: data.status
    }
    const { response, error } = await changeExpenseTypeStatus(object);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      
      getExpenseTypeListPage(pagination)
      
    } else if (error) {
      if(error.response.status == 400){
        error.message && toast.error(error.response.data.message);
      }else{
        error.message && toast.error(error.message);
      } 
    }
  }

  const deleteExpenseTypeRow = async data => {
    
    const object = {
      id: data.id
    }
    const { response, error } = await deleteExpenseType(object);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      getExpenseTypeListPage(pagination)
      
    } else if (error) {
      if(error.response.status == 400){
        error.message && toast.error(error.response.data.message);
      }else{
        error.message && toast.error(error.message);
      } 
    }
  }

  const fillExpenseType = (expenseType) =>{
    
    setValue([
      { id : expenseType.id },
      { name : expenseType.name },
    ])
    setUpdate(true)

  }

  const renderTable = (expenseTypes) => {
        let data = expenseTypes.map((prop, key) => {
            return  (
                 <tr key={prop.id}>
                    <th scope="row">{prop.id}</th>
                    <td>{prop.name}</td>
                    <td>
                      {
                        prop.status == 1? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          Activo
                        </Badge> : 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          Inactivo
                        </Badge>
                      }
                    </td>
                    <td className="text-left">
                    <UncontrolledDropdown>
                        <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                        >
                            <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                                href="#"
                                onClick={() => fillExpenseType(prop)}>Actualizar
                            </DropdownItem>
                            <DropdownItem
                                href="#"
                                onClick={() => changeStatusExpenseType(prop)}>Cambiar Estado
                            </DropdownItem>
                            <DropdownItem
                                href="#"
                                onClick={()=> deleteExpenseTypeRow(prop)}>Eliminar
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                
                </tr>
                
            )
        })
        return data;
  }
   
  const onPagination = (pagination)=>{
    getExpenseTypeListPage(pagination);
  }
   
  useEffect(()=>{
    getExpenseTypeListPage(pagination);
    getUserList()
  }, [])

  return (
    <>
        <Row>
                <Col className="order-xl-2 mb-5 mb-xl-0" xl="6">
                    <Card className="shadow">
                        <CardHeader className="border-1">
                            <h3 className="mb-0">Lista tipo de Gastos</h3>
                        </CardHeader>
                        
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Código</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Estado</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {expenseTypes.length > 0 && renderTable(expenseTypes)}
                                </tbody>
                            </Table>
                        
                        <CardFooter className="py-4">
                            <PaginationComponent pagination={pagination} handlePage={onPagination}/>
                        </CardFooter>
                    </Card>
                </Col>
                <Col className="order-xl-1" xl="6">
                    <Card className="shadow">
                        <CardHeader className="bg-white border-1">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Crear Tipo deGasto</h3>
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={handleSubmit(saveExpenseType)}>
                                <h6 className="heading-small text-muted mb-4">
                                    Información del Tipo de Gasto
                                </h6>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col lg="6">
                                          <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="name">
                                                Nombre
                                            </label>
                                            <Input 
                                                name = "name"
                                                className="form-control-alternative"
                                                id="name"
                                                placeholder="Nombre"
                                                type="text"
                                                innerRef={register({ required: true})} />
                                            {!!errors?.name?.types?.required && <FormText>Campo requerido.</FormText>}
                                          </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <div className="text-center">
                                                {
                                                !update ? 
                                                <Button className="my-4" color="primary" type="submit" >
                                                    Guardar
                                                </Button>
                                                :
                                                <>
                                                    <Button className="my-4" color="danger" type="button" onClick={()=>{reset(); setUpdate(false)}} >
                                                    Cancelar
                                                    </Button>
                                                    <Button className="my-4" color="info" type="submit" >
                                                        Actualizar datos
                                                    </Button>
                                                </>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Input
                                        className="form-control-alternative"
                                        hidden
                                        id="id"
                                        name="id"
                                        type="number"
                                        innerRef={register} 
                                    />
                                </div>
                            </Form>
            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        
    </>
  );
};

export default ExpenseType;


