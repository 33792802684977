/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState,useEffect} from "react";
import moment from 'moment'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormText,
  Form,
  Input,
  Row,
  Col,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Badge,
  Table,
  Button
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useApi, useToast } from "../../../hooks";
import PaginationComponent from "../../../components/Pagination";

import { useSelector } from 'react-redux'
import { getFilterUsers } from "../../../selectors/jobs";
import { CURRENCY } from '../../../variables/app';

const Expense = () => {

  const company = useSelector(state => state.company.company)
  const { register, handleSubmit, errors, setValue, reset  } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange"
  });

  var date = moment().format('YYYY-MM-DD'); 
  const [expenses, setExpenses] = useState([])
  const [startDate, setStartDate] = useState(date)
  const [endDate, setEndDate] = useState(date)
  const [users, setUsers] = useState([])
  const [user, setUser] = useState(null)
  const [expenseTypes, setExpenseTypes] = useState([])
  const [update, setUpdate] = useState(false)
  const [pagination, setPagination] = useState({
    perPage: 50,
    page:1,
    pages:0
  });

  const renderOptions = (list) => {
    return list.map((prop,key) => {
      return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
    });
  }

  const toast = useToast();
  const { storeExpense, getExpensesUsersPaginationDate, changeExpenseStatus, deleteExpense, getUsers, getExpenseTypes } = useApi();

  const getUserList = async () =>{
   
    const { response, error } = await getUsers();
    if (response) {
      const { content, message } = response.data;
      setUsers(getFilterUsers(content, company.id));
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }

  const getExpenseTypeList = async () =>{
   
    const { response, error } = await getExpenseTypes();
    if (response) {
      const { content, message } = response.data;
      setExpenseTypes(content);
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }

  const getExpenseListPageDate = async (paginationObject) =>{
   
    const { response, error } = await getExpensesUsersPaginationDate(paginationObject, user, startDate, endDate);
    if (response) {
      const { content, message } = response.data;
      setExpenses(content.data);
      setPagination({
        perPage: content.per_page,
        page: content.current_page,
        pages: content.last_page
      })
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }
  
  const saveExpense = async data => {
    if(!update){
      delete data.id;
    }
    //console.log(data);
    const { response, error } = await storeExpense(data);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      if(!update){
        //setExpenses((lastExpenses)=> [...lastExpenses, content])
        getExpenseListPageDate(pagination)
      }else{
        getExpenseListPageDate(pagination)
        setUpdate(false)
      }
      reset();
    } else if (error) {
      if(error.response.status == 400){
        error.message && toast.error(error.response.data.message);
      }else{
        error.message && toast.error(error.message);
      } 
    }
  }

  const changeStatusExpense = async data => {
    
    const object = {
      id: data.id,
      status: data.status
    }
    const { response, error } = await changeExpenseStatus(object);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      
      getExpenseListPageDate(pagination)
      
    } else if (error) {
        error.message && toast.error(error.message);
    }
  }

  const deleteExpenseRow = async data => {
    
    const object = {
      id: data.id
    }
    const { response, error } = await deleteExpense(object);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      getExpenseListPageDate(pagination)
      
    } else if (error) {
      if(error.response.status == 400){
        error.message && toast.error(error.response.data.message);
      }else{
        error.message && toast.error(error.message);
      } 
    }
  }

  const fillExpense = (expense) =>{
    
    setValue([
      { id : expense.id },
      { name : expense.name },
    ])
    setUpdate(true)

  }

  const renderTable = (expenses) => {
        let data = expenses.map((prop, key) => {
            return  (
                 <tr key={prop.id}>
                    <th scope="row">{prop.id}</th>
                    <td>{prop.user.name}</td>
                    <td>{prop.expense_type.name}</td>
                    <td>{prop.detail}</td>
                    <td>{prop.date}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                    <td>
                      {
                        prop.status == 1? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          Activo
                        </Badge> : 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          Eliminado
                        </Badge>
                      }
                     
                    </td>
                    <td className="text-left">
                    <UncontrolledDropdown>
                        <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                        >
                            <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                                href="#"
                                onClick={()=> deleteExpenseRow(prop)}>Eliminar
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                
                </tr>
                
            )
        })
        return data;
  }
   
  const onPagination = (pagination)=>{
    getExpenseListPageDate(pagination);
  }
   
  useEffect(()=>{
    //getExpenseListPageDate(pagination);
    getUserList()
    getExpenseTypeList()
  }, [])

  return (
    <>
        <Row>    
          <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                    <CardHeader className="bg-white border-1">
                        <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">Crear Gasto</h3>
                        </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={handleSubmit(saveExpense)}>
                            <h6 className="heading-small text-muted mb-4">
                                Información del Gasto
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="4">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="userId"
                                        >
                                          Cobrador
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="userId"
                                          name="userId"
                                          placeholder="Seleccionar una opción"
                                          type="select"
                                          innerRef={register({ required: true })} >
                                            <option></option>
                                            {renderOptions(users)}
                                        </Input>
                                        {!!errors?.userId?.types?.required && <FormText>Campo requerido.</FormText>}
                                      </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="expenseTypeId"
                                        >
                                          Tipo de Gasto
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="expenseTypeId"
                                          name="expenseTypeId"
                                          placeholder="Seleccionar una opción"
                                          type="select"
                                          innerRef={register({ required: true })} >
                                            <option></option>
                                            {renderOptions(expenseTypes)}
                                        </Input>
                                        {!!errors?.expenseTypeId?.types?.required && <FormText>Campo requerido.</FormText>}
                                      </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                      <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="date">
                                            Fecha
                                        </label>
                                        <Input 
                                            name = "date"
                                            className="form-control-alternative"
                                            id="date"
                                            defaultValue={date}
                                            placeholder="Fecha"
                                            type="date"
                                            innerRef={register({ required: true})} />
                                        {!!errors?.date?.types?.required && <FormText>Campo requerido.</FormText>}
                                      </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="detail">
                                          Detalle
                                      </label>
                                      <Input 
                                          name = "detail"
                                          className="form-control-alternative"
                                          id="detail"
                                          placeholder="Detalle"
                                          type="text"
                                          innerRef={register({ required: true})} />
                                      {!!errors?.detail?.types?.required && <FormText>Campo requerido.</FormText>}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                    <FormGroup>
                                      <label
                                          className="form-control-label"
                                          htmlFor="value">
                                          Valor
                                      </label>
                                      <Input 
                                          name = "value"
                                          className="form-control-alternative"
                                          id="value"
                                          placeholder="Valor"
                                          type="number"
                                          innerRef={register({ required: true})} />
                                      {!!errors?.value?.types?.required && <FormText>Campo requerido.</FormText>}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="4">
                                      <div className="text-center">
                                          {
                                          !update ? 
                                          <Button className="my-4" color="primary" type="submit" >
                                              Guardar
                                          </Button>
                                          :
                                          <>
                                              <Button className="my-4" color="danger" type="button" onClick={()=>{reset(); setUpdate(false)}} >
                                              Cancelar
                                              </Button>
                                              <Button className="my-4" color="info" type="submit" >
                                                  Actualizar datos
                                              </Button>
                                          </>
                                          }
                                      </div>
                                  </Col>
                                  <Input
                                    className="form-control-alternative"
                                    hidden
                                    id="id"
                                    name="id"
                                    type="number"
                                    innerRef={register} 
                                  />
                                  
                                </Row>
                            </div>
                        </Form>
        
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <br/>
        <Row>
        <Col className="mb-5 mb-xl-0" xl="12">
          <Card className="shadow">
              <CardHeader className="border-1">
                  <h3 className="mb-0">Lista de Gastos</h3>
              </CardHeader>
              <CardBody>
                
                <Row>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="startDate">
                          Fecha Inicio
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="startDate"
                        name="startDate"
                        value={startDate}
                        onChange={e=>setStartDate(e.target.value)}
                        type="date" >
                      </Input>
                                
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="endDate">
                          Fecha Fin
                        </label>
                        <Input 
                          name = "endDate"
                          className="form-control-alternative"
                          id="endDate"
                          value={endDate}
                          onChange={e=>setEndDate(e.target.value)}
                          type="date" />
                      </FormGroup>
                    </Col> 
                    <Col lg="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="user"
                        >
                          Cobrador
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="user"
                          name="user"
                          placeholder="Seleccionar una opción"
                          type="select"
                          onChange={e=>setUser(e.target.value)} >
                            <option></option>
                            {renderOptions(users)}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col lg="2">
                      <Button className="my-4" color="primary" type="submit" onClick={()=>getExpenseListPageDate(pagination)}>
                        Buscar
                      </Button>
                    </Col>                                   
                  </Row>
                  <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                          <tr>
                              <th scope="col">Código</th>
                              <th scope="col">Cobrador</th>
                              <th scope="col">Tipo de Gasto</th>
                              <th scope="col">Detalle</th>
                              <th scope="col">Fecha</th>
                              <th scope="col">Valor</th>
                              <th scope="col">Estado</th>
                              <th scope="col">Acciones</th>
                          </tr>
                      </thead>
                      <tbody>
                          {expenses.length > 0 && renderTable(expenses)}
                      </tbody>
                  </Table>
                </CardBody>
              <CardFooter className="py-4">
                  <PaginationComponent pagination={pagination} handlePage={onPagination}/>
              </CardFooter>
          </Card>
      </Col>
    </Row>
        
    </>
  );
};

export default Expense;


