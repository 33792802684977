import React,{useState,useEffect} from 'react';
import moment from 'moment'
import {
    Button,
    FormGroup,
    FormText,
    Form,
    Input,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Table,
    Badge
  } from "reactstrap";
import { useToast,useApi } from '../../../hooks';
import { useForm } from "react-hook-form";
import { CURRENCY } from 'variables/app';


const History = ({customer}) => {

    if(!customer){
      customer = {
        id:0,
        name:""
      }
    }
   
    var date = moment().format('YYYY-MM-DD'); 
    const [dateQuote, setDateQuote] = useState(date)
    const [valueQuote, setValueQuote] = useState(null)
    const [collapse, setCollapse] = useState("loanList")
    const [toggle, setToggle] = useState(null)
    const [loans, setLoans] = useState([])
    const [loan, setLoan] = useState(null)
    const [quotes, setQuotes] = useState([])
    const [paymentModes, setPaymentModes] = useState([])

    const toast = useToast();
    const { getLoansByCustomer, getQuotesByLoans, storeQuote, deleteQuote, getPaymentsMode, updateLoan, changeLoanStatus } = useApi();

    const { register, handleSubmit, errors, setValue, reset  } = useForm({
      validateCriteriaMode: "all",
      mode: "onChange"
     });

     const updateLoanRow = async data => {
      //console.log(data);
      const { response, error } = await updateLoan(data);
      if (response) {
        //console.log(response);
        const { content, message } = response.data;
        toast.success(message);
        getLoans()
        //reset();
      } else if (error) {
        if(error.response.status == 400){
          error.message && toast.error(error.response.data.message);
        }else{
          error.message && toast.error(error.message);
        } 
      }
    }

    const updateLoanStatus = async status => {
      if (window.confirm("Está seguro que desea Cambiar el estado del prestamo?")) {
        const { response, error } = await changeLoanStatus({id: loan.id, status});
        if (response) {
          //console.log(response);
          const { content, message } = response.data;
          toast.success(message);
          getLoans()
          reset();
        } else if (error) {
          if(error.response.status == 400){
            error.message && toast.error(error.response.data.message);
          }else{
            error.message && toast.error(error.message);
          } 
        }
      }
      
    }

    const getPaymentsModeList = async () =>{
   
      const { response, error } = await getPaymentsMode();
      if (response) {
        const { content, message } = response.data;
        setPaymentModes(content);
      } else if (error) {
          error.message && toast.error(error.message);
      }
  
    }

    const removeQuote = async (data) =>{
      if (window.confirm("Está seguro que desea eliminar la Cuota?")) {
        const { response, error } = await deleteQuote({id: data.id});
        if (response) {
          const { content, message } = response.data;
          toast.success(message);
          getQuotes(data.loan_id)
        } else if (error) {
          if(error.response.status == 400){
            error.message && toast.error(error.response.data.message);
          }else{
            error.message && toast.error(error.message);
          } 
        }
      } 
    }
    
    const saveQuote = async () => {
        
        const { response, error } = await storeQuote(
          {
            loanId: loan.id,
            date: dateQuote,
            value: valueQuote
          }
        );
        if (response) {
          const { content, message } = response.data;
          toast.success(message);
          getQuotes(loan.id)
        } else if (error) {
          if(error.response.status == 400){
            error.message && toast.error(error.response.data.message);
          }else{
            error.message && toast.error(error.message);
          } 
        }
    }

    const getLoans = async () =>{
   
        const { response, error } = await getLoansByCustomer(customer.id);
        if (response) {
          const { content, message } = response.data;
          setLoans(content);
        } else if (error) {
            error.message && toast.error(error.message);
        }
    
      }

    const getQuotes = async (loan_id) =>{
  
      const { response, error } = await getQuotesByLoans(loan_id);
      if (response) {
        const { content, message } = response.data;
        setQuotes(content);
        setCollapse("quoteList")
      } else if (error) {
          error.message && toast.error(error.message);
      }
  
    }

    const renderOptions = (list) => {
        return list.map((prop,key) => {
        return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
        });
    }

    const renderOptionsQuotes = (number) => {
        let element = [];
        for (let index = 1; index <= number; index++) {
            element.push( <option key ={`q_${index}`} value={index}>{index}</option> )
        }
        return element
    }

    const renderTable = (customers) => {
      let data = customers.map((prop, key) => {
          return  (
                <tr key={prop.id} onClick={()=>{getQuotes(prop.id); setLoan(prop)}} style={
                  {
                    cursor: "pointer"
                  }
                }>
                  <th scope="row">{prop.startDate}</th>
                  <td>{CURRENCY.format(prop.value)}</td>
                  <td>{CURRENCY.format(prop.total)}</td>
                  <td>{CURRENCY.format(prop.balance)}</td>
                  <td>{prop.endDate}</td>
                  <td>{prop.finalDate}</td>
                  <td>{
                        prop.status == 1? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-info" />
                          Activo
                        </Badge> : 
                        prop.status == 2? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          Finalizado a tiempo
                        </Badge> : 
                        prop.status == 3? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          Inactivo
                        </Badge>: 
                         prop.status == 4? 
                         <Badge color="" className="badge-dot mr-4">
                           <i className="bg-danger" />
                           Eliminado
                         </Badge> : 
                          prop.status == 5? 
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            Finalizado Cuotas atrasadas
                          </Badge> : 
                           prop.status == 6? 
                           <Badge color="" className="badge-dot mr-4">
                             <i className="bg-success" />
                             Finalizado Muy Atrasado
                           </Badge> : 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-danger" />
                          Eliminado
                        </Badge>
                      }</td>
              </tr>
              )
      })
      return data;
    }   

    const renderQuotesTable = (customers) => {
      let id = customers.length+1;
      let data = customers.map((prop, key) => {
          id--
          return  (
                <tr key={"q"+prop.id}>
                  <td>{`CUOTA ${id}`}</td>
                  <td>{prop.date}</td>
                  <td>{CURRENCY.format(prop.value)}</td>
                  <td ><u  style={
                  {
                    cursor: "pointer",
                    color: 'red',

                  }
                  } onClick={()=>removeQuote(prop)}>Eliminar</u></td>
              </tr>
              )
      })
      return data;
    }   

    useEffect(()=>{
      getLoans()
      getPaymentsModeList()
    }, [])
    
    return (
        <>
          <h6 className="heading-small text-muted mb-4">
            Lista de prestamos
          </h6>
          <Row>
            <Col lg="4">
              <span className="h6 surtitle text-muted">Nombre</span>
              <span className="d-block h3">{customer.name}</span>
            </Col>
            <Col lg="4">
              <span className="h6 surtitle text-muted">Cédula</span>
              <span className="d-block h3">{customer.identification}</span>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <span className="h6 surtitle text-muted">Dirección</span>
              <span className="d-block h3">{customer.address}</span>
            </Col>
            <Col lg="4">
              <span className="h6 surtitle text-muted">Celular</span>
              <span className="d-block h3">{customer.phone}</span>
            </Col>
          </Row>
          <br />
          <div className="accordion">
            <Card className="card-plain">
              <CardHeader
                role="tab"
                onClick={() => setCollapse("loanList")}
                aria-expanded={collapse == "loanList"}
                style={
                  {
                    cursor: "pointer"
                  }
                }
              >
                <h5 className="mb-0">Prestamos</h5>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={collapse == "loanList"}
              >
                <CardBody style={
                  {
                    padding:0,
                    margin:0
                  }
                }>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Fecha del Préstamo</th>
                        <th scope="col">Valor Prestado</th>
                        <th scope="col">Total Deuda</th>
                        <th scope="col">Saldo</th>
                        <th scope="col">Fecha Límite de Pago</th>
                        <th scope="col">Fecha Final de Pago</th>
                        <th scope="col">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loans.length > 0 && renderTable(loans)}
                    </tbody>
                  </Table>
                </CardBody>
              </Collapse>
            </Card>
            <Card className="card-plain">
              <CardHeader
                role="tab"
                aria-expanded={collapse == "loanList"}
              >
                <h5 className="mb-0">Lista de Cuotas {collapse == "quoteList" && <>
                <span><b>( Fecha:</b> {loan.startDate}, <b>Prestado:</b> {CURRENCY.format(loan.value)}, <b>Saldo:</b> {CURRENCY.format(loan.balance)} <b>)</b> </span>
                <Badge style={
                  {
                    cursor: "pointer"
                  }
                } className="badge-info badge-lg" onClick={()=>{setToggle("loan")}}>Actualizar Préstamo</Badge> - <Badge style={
                  {
                    cursor: "pointer"
                  }
                } color="primary badge-lg" onClick={()=>{setToggle("quote");setValueQuote(loan.quota/1000)}}>Agregar Cuota</Badge> - <Badge style={
                  {
                    cursor: "pointer"
                  }
                } color="warning badge-lg" onClick={()=>updateLoanStatus(3)}>{ loan.status != 3 && "Inactivar Préstamo"} { loan.status == 3 && "Activar Préstamo"}</Badge> - <Badge style={
                  {
                    cursor: "pointer"
                  }
                } color="danger badge-lg" onClick={()=>updateLoanStatus(4)}>Eliminar Préstamo</Badge></>}</h5>
              </CardHeader>
              <Collapse
                role="tabpanel"
                isOpen={collapse == "quoteList"}
              >
                <CardBody>
                  {
                    toggle == "loan" && 
                    <>
                      <Row>
                        <Col xl="12">
                          <Form onSubmit={handleSubmit(updateLoanRow)}>
                            <h6 className="heading-small text-muted mb-4">
                              Actualizar préstamo de {customer.name}
                            </h6>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="date"
                                  >
                                    Fecha de entrega del préstamo
                                  </label>
                                  <Input 
                                      name = "date"
                                      className="form-control-alternative"
                                      id="date"
                                      type="date"
                                      defaultValue={loan.startDate}
                                      innerRef={register({ required: true})} 
                                  />
                                  {!!errors?.date?.types?.required && <FormText>Campo requerido.</FormText>}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="paymentModeId"
                                  >
                                    Modo de Pago
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="paymentModeId"
                                    name="paymentModeId"
                                    placeholder="Seleccionar una opción"
                                    type="select"
                                    defaultValue={loan.payment_mode_id}
                                    innerRef={register({ required: true })} >
                                      <option></option>
                                      {renderOptions(paymentModes)}
                                  </Input>
                                  {!!errors?.name?.types?.required && <FormText>Campo requerido.</FormText>}
                                </FormGroup>
                              </Col>
                              
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="value"
                                  >
                                    Valor
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="value"
                                    name="value"
                                    type="number"
                                    defaultValue={loan.value/1000}
                                    innerRef={register({ required: true})} 
                                  />
                                  {!!errors?.value?.types?.required && <FormText>Campo requerido.</FormText>}
                                </FormGroup>
                              </Col>
                              
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="percentage"
                                  >
                                    Porcentaje (%)
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="percentage"
                                    name="percentage"
                                    type="number"
                                    defaultValue={loan.percentage}
                                    innerRef={register({ required: true})} 
                                  />
                                  {!!errors?.percentage?.types?.required && <FormText>Campo requerido.</FormText>}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="quotes"
                                  >
                                    Número de cuotas
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="quotes"
                                    name="quotes"
                                    placeholder="Seleccionar una opción"
                                    type="select"
                                    defaultValue={loan.quotes}
                                    innerRef={register({ required: true })} >
                                      <option></option>
                                      {renderOptionsQuotes(120)}
                                  </Input>
                                  {!!errors?.quotes?.types?.required && <FormText>Campo requerido.</FormText>}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="quota"
                                  >
                                    Cuota
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="quota"
                                    name="quota"
                                    placeholder="Seleccionar una opción"
                                    type="number"
                                    defaultValue={Math.trunc(loan.quota/1000)}
                                    innerRef={register({ required: true })} >
                                  </Input>
                                  {!!errors?.quota?.types?.required && <FormText>Campo requerido.</FormText>}
                                </FormGroup>
                              </Col>

                              
                            </Row>
                            <Row>
                            <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="total"
                                  >
                                    Total
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="total"
                                    name="total"
                                    placeholder="Seleccionar una opción"
                                    type="number"
                                    defaultValue={ Math.trunc(loan.total/1000)}
                                    innerRef={register({ required: true })} >
                                      
                                  </Input>
                                  {!!errors?.total?.types?.required && <FormText>Campo requerido.</FormText>}
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <div className="text-center">
                                  <Button className="my-4" color="primary" type="submit" >
                                      Guardar
                                  </Button>
                                </div>
                              </Col>
                              <Input
                                className="form-control-alternative"
                                hidden
                                id="id"
                                name="id"
                                type="number"
                                defaultValue={loan.id}
                                innerRef={register} 
                              />
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </>
                  }
                  {
                    toggle == "quote" && 
                    <>
                      <Row>
                        <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="date"
                              >
                                Fecha de la cuota
                              </label>
                              <Input 
                                  name = "date"
                                  className="form-control-alternative"
                                  id="date"
                                  type="date" 
                                  defaultValue={dateQuote}
                                  onChange={e=>setDateQuote(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="quota_value"
                              >
                                Valor
                              </label>
                              <Input 
                                  name = "quota_value"
                                  className="form-control-alternative"
                                  id="quota_value"
                                  type="number"
                                  defaultValue={Math.trunc(loan.quota/1000)}
                                  onChange={e=>setValueQuote(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <div className="text-center">
                              <Button className="my-4" color="primary" type="submit" onClick={()=> saveQuote()}>
                                  Guardar
                              </Button>
                            </div>
                          </Col>
                      </Row>
                    </>
                  }
                  
                  <Table className="align-items-center table-flush" responsive >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Cuota</th>
                        <th scope="col">Fecha Pago</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quotes.length > 0 && renderQuotesTable(quotes)}
                    </tbody>
                  </Table>
                </CardBody>
              </Collapse>
            </Card>
          
          </div>
          
        </>
    );
};

export default History;