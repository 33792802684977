/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState,useEffect} from "react";
import moment from 'moment'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormText,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  Badge,
  Table,
  Button
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { useForm } from "react-hook-form";
import { useApi, useToast } from "../../hooks";
import PaginationComponent from "../../components/Pagination";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Backup = () => {

  const { register, handleSubmit, errors, setValue, reset  } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange"
  });

  const toast = useToast();
  const { getBackup } = useApi();

 
  const makeBackup = async data => {
   
    const { response, error } = await getBackup();
    if (response) {
      const { content, message } = response.data;
      toast.success(message);
      const link = document.createElement('a')
      link.href = content
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else if (error) {
        error.message && toast.error(error.message);
    } 
  }

  
  return (
    <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="shadow">
                        <CardHeader className="bg-white border-1">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Crear Backup</h3>
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={handleSubmit(makeBackup)}>
                                <h6 className="heading-small text-muted mb-4">
                                    Copia de Seguridad
                                </h6>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col lg="6">
                                          <FormGroup>
                                            <label
                                              className="form-control-label"
                                              htmlFor="userId"
                                            >
                                              Formato
                                            </label>
                                            <Input
                                              className="form-control-alternative"
                                              id="userId"
                                              name="userId"
                                              placeholder="Seleccionar una opción"
                                              type="select"
                                              innerRef={register({ required: true })} >
                                                <option value="sql">SQL</option>
                                                <option value="csv">CSV</option>
                                            </Input>
                                            {!!errors?.userId?.types?.required && <FormText>Campo requerido.</FormText>}
                                          </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                          <div className="text-center">       
                                            <Button className="my-4" color="primary" type="submit" >
                                                Guardar
                                            </Button>
                                          </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>

  );
};

export default Backup;


