/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState,useEffect}from "react";

// reactstrap components
import { Container, Row, Col, Button, ButtonGroup } from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import Expense from "./Expense";
import ExpenseType from "./ExpenseType";

const Settings = () => {

    const [rSelected, setRSelected] = useState(1);
    return (
        <>
        <Header />
        
        {/* Page content */}
        <Container className="mt--7" fluid>
            <div className="text-center" Style="margin-bottom:10px">
                <ButtonGroup>
                    <Button color="default" onClick={() => setRSelected(1)} active={rSelected === 1}><i className="ni ni-spaceship" /> Gastos </Button>
                    <Button color="default" onClick={() => setRSelected(2)} active={rSelected === 2}><i className="ni ni-bag-17" /> Tipo de Gastos</Button>
                </ButtonGroup>
            </div>
            {
                rSelected ===1 ?
                <> 
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Expense />
                        </Col>
                    </Row>
                </> :  
                <>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <ExpenseType />
                        </Col>
                    </Row>
                </> 
            }
            
        </Container>
        </>
    );
};

export default Settings;


